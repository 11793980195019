const formatTime = (time) => {
  let formattedTime = time;
  if (time < 10) formattedTime = `0${time}`;
  return `${formattedTime}:00`;
};

const formatDate = (date) => {
  const newDate = date.split("T")[0];
  const [year, month, day] = newDate.split("-");
  return `${day}/${month}/${year}`;
};

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
  if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
  return null;
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export { formatTime, formatDate, formatPhoneNumber, formatCurrency };
