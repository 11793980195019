const mutations = {
  setUser: (state, payload) => {
    state.user = payload;
  },
  setAuthentication: (state) => {
    state.isAuthenticated = !state.isAuthenticated;
  },
};

export default mutations;
