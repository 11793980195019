import { api } from "../../../services/apiClient";
import router from "../../../routes";

const actions = {
  deleteReservation: async (_, reservation_id) => {
    const res = await api.delete(`reservation/${reservation_id}`);
    return res.data;
  },
  createReservation: async (_, payload) => {
    const res = await api.post("reservation", payload);

    if (res && !res.data.confirmed) {
      const reservations = res.data.reservations.map((res) => res._id);
      router.push({
        path: `/pagamento?reservations=${reservations}`,
      });
    } else router.push("/minhas-reservas");
  },
};

export default actions;
