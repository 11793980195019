<template>
  <div id="teste">
    <div class="screen">
      <div id="main" v-show="screen">
        <Section title="Fotos dos Campeonatos" :gridType="1">
          <div class="camp">
            <sui-dropdown
              id="search"
              :options="selecao"
              placeholder="Busque um torneio"
              search
              selection
              v-model="current"
            />

            <hr />

            <swiper :options="swiperOptions" class="camp-cards">
              <swiper-slide
                :key="index.value"
                v-for="index in fotos"
                style="height: 40vh"
              >
                <sui-card id="card">
                  <sui-card-content>
                    <sui-card-header id="header">
                      {{ index.text }}</sui-card-header>

                    <img
                      loading="lazy"
                      :src="index.principal"
                      :alt="`Carrossel fotos praticando esportes ${index.text}`"
                      @click="selection(index)"
                    />
                  </sui-card-content>
                </sui-card>
              </swiper-slide>
              <div
                class="swiper-button-prev"
                slot="button-prev"
                id="button"
              ></div>
              <div
                class="swiper-button-next"
                slot="button-next"
                id="button"
              ></div>
            </swiper>
            <hr />
          </div>
        </Section>
      </div>
      <div id="secondary" v-show="!screen">
        <div id="main" style="margin-top: 2rem; text-align: center">
          <h1>{{ option.text }}</h1>
          <img :src="option.principal" :alt="option.text" />
        </div>

        <div id="modal">
          <!-- <sui-button>
            <a
            href="https://drive.google.com/drive/folders/1ObUgiSHAqdQ7FdsoLsYMmGXmEKPElVAD"
            target="_blank"
            rel="noopener noreferrer"
            v-if="option.foto != null"

            >Fotos</a>
            </sui-button> -->

        </div>
        <div id="modal">
          <!-- <router-link :to=" window.option.foto">home</router-link> -->
          <sui-button @click="foto_funcao" v-if="option.foto != null">Fotos</sui-button>
          <sui-button @click="toggle" v-if="option.regulamento != null">Regulamento</sui-button>
          <sui-modal v-model="open">
            <sui-modal-header>Regulamento</sui-modal-header>
            <sui-modal-content>
              <sui-modal-description style="heigth: 50rem">
                <iframe
                  :src="option.regulamento"
                  frameBorder="0"
                  scrolling="auto"
                  width="100%"
                  style="height: 75vh"
                ></iframe>
              </sui-modal-description>
            </sui-modal-content>
            <sui-modal-actions>
              <sui-button positive @click="toggle"> Fechar </sui-button>
            </sui-modal-actions>
          </sui-modal>
          <sui-button @click="alterar" v-if="option.resultado != null"
            >Resultado</sui-button
          >
          <sui-modal v-model="abrir">
            <sui-modal-header>Resultado</sui-modal-header>
            <sui-modal-content>
              <sui-modal-description style="heigth: 50rem">
                <iframe
                  :src="option.resultado"
                  frameBorder="0"
                  scrolling="auto"
                  width="100%"
                  style="height: 75vh"
                ></iframe>
              </sui-modal-description>
            </sui-modal-content>
            <sui-modal-actions>
              <sui-button positive @click="toggle"> Fechar </sui-button>
            </sui-modal-actions>
          </sui-modal>
        </div>

        <br />
        <div id="gallery">
          <div
            id="pics"
            loading="lazy"
            :key="number"
            v-for="number in option.outras"
          >
            <img :src="number" style="width: 18vw; margin-bottom: 2vw" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Section from "../components/Home/Section.vue";
import Footer from "../components/Footer.vue";
import subData from "../assets/data/torneios/sub.JSON";
import futvolei2021Data from "../assets/data/torneios/futvolei2021.JSON";
import inicianteData from "../assets/data/torneios/iniciante.JSON";
import intermediarioData from "../assets/data/torneios/intermediario.JSON";
import avancadoData from "../assets/data/torneios/avancado.JSON";
import volei2022Data from "../assets/data/torneios/volei2022.JSON";
import volei2023Data from "../assets/data/torneios/volei2023.JSON";
import vp_cearense_2023 from "../assets/data/torneios/vp_cearense_2023.JSON";
import cearense_bt_2023 from "../assets/data/torneios/cearense_bt_2023.JSON";
import aulao_lili_Larissa_interno from "../assets/data/torneios/aulao_lili_Larissa_interno.JSON";


export default {
  components: {
    Section,
    Footer,
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },

  watch: {
    current: function (val) {
      for (var i in this.fotos) {
        if (this.fotos[i].value == val) {
          this.option = this.fotos[i];
        }
      }
      this.screen = false;
    },
  },
  methods: {
    selection(selected) {
      this.option = selected;
      this.screen = false;
    },
    toggle() {
      this.open = !this.open;
    },
    alterar() {
      this.abrir = !this.abrir;
    },
    foto_funcao(){
      window.location.href = this.option.foto;

    }
  },
  data: () => ({
    open: false,
    abrir: false,
    screen: true,
    current: null,
    selecao: [
      {
        key: "1",
        value: "1",
        text: "Torneio cearense de praia sub 15 e sub 19",
      },
      { key: "2", value: "2", text: "Torneio MM Futevolei 2021" },
      {
        key: "3",
        value: "3",
        text: "Torneio MM Vôlei de Praia 2021 - Iniciante",
      },
      {
        key: "4",
        value: "4",
        text: "Torneio MM Vôlei de Praia 2021 - Intermediário",
      },
      {
        key: "5",
        value: "5",
        text: "Torneio MM Vôlei de Praia 2021 - Avançado",
      },
      {
        key: "6",
        value: "6",
        text: "Torneio MM Vôlei de Praia 2022",
      },
      {
        key: "7",
        value: "7",
        text: "Torneio Cearense de beach tennis 2023",
      },
      {
        key: "8",
        value: "8",
        text: "Torneio Arena mm",
      },
      {
        key: "9",
        value: "9",
        text: "Cearense de volei de praia 2023 Etapa Arena MM",
      },
      {
        key: "10",
        value: "10",
        text: "Aulão Lili e Larissa",
      },
    ],
    option: {
      text: null,
      principal: null,
      outras: null,
      regulamento: null,
      resultado: null,
      foto: null,
    },
    fotos: {
      sub: subData[0],
      futvolei2021: futvolei2021Data[0],
      iniciante: inicianteData[0],
      intermediario: intermediarioData[0],
      avancado: avancadoData[0],
      volei2022: volei2022Data[0],
      cearense_bt_2023:cearense_bt_2023[0],
      volei2023Data:volei2023Data[0],
      vp_cearense_2023:vp_cearense_2023[0],
      aulao_lili_Larissa_interno:aulao_lili_Larissa_interno[0],


    },

    swiperOptions: {
      slidesPerView: 4,
      loop: true,

      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1300: {
          slidesPerView: 4,
        },
        972: {
          slidesPerView: 3,
        },

        665: {
          slidesPerView: 2,
        },

        200: {
          slidesPerView: 1,
        },
      },
    },
  }),
};
</script>

<style lang="scss" scoped>
.screen {
  background: $light-black url(../assets/images/background.png);
  width: 100vw;
  min-width: 350px;
  padding: 8rem;
  margin-bottom: -2rem;
  overflow-x: hidden;

  #secondary {
    @media (max-width: 847px) {
      margin-left: -3rem;
    }
    #main {
      display: block;
      width: 76.5vw;
      margin-top: 2rem;
      text-align: center;
      @media (max-width: 1920px) {
        margin-left: 6rem;
      }
      @media (max-width: 1280px) {
        margin-left: 0;
      }

      img {
        width: 30vw;
        min-width: 20rem;
        margin-bottom: 2rem;
      }

      h1 {
        text-align: center;
        color: $white;
      }
    }
    #gallery {
      background: #d6eae7;
      padding: 2rem;
      #pics {
        display: inline;
        img {
          margin-left: 2vw;
          background: $light-black;
        }
      }
      @media (max-width: 847px) {
        width: 75vw;
        margin-left: 0;
      }
    }
    #modal {
      button {
        @include button;
        background: $main-green;
        color: $white;
        margin-left: 16vw;
        width: 20%;
      }
      @media (max-width: 1020px) {
        button {
          min-width: 15rem;
          margin-left: 20vw;
          margin-bottom: 1rem;
          width: 25%;
        }
      }
      @media (max-width: 847px) {
        button {
          margin-left: 17vw;
          width: 30%;
        }
      }
    }
  }

  .camp {
    @media (max-width: 665px) {
      width: 100vw;

      .camp-cards {
        #card {
          width: 100vw;
        }
      }
      #search {
        width: 25%;
        margin-left: 37.5%;
        margin-right: 37.5%;
      }
    }

    height: 60vh;
    #search {
      width: 25vw;
      margin-left: 37.5vw;
      margin-right: 37.5vw;
      margin-bottom: 5rem;
    }
    @media (max-width: 665px) {
      #search {
        width: 25%;
        margin-left: 37.5%;
        margin-right: 37.5%;
      }
    }
    hr {
      margin-bottom: 7rem;
    }

    #card {
      background: #cde1de;
      height: 27.5rem;
      width: 20rem;
      padding: 0.5rem;
      padding: 0.5rem 0 0.5rem 0;
      border-radius: 3rem;
      img {
        background: $light-black;
      }
      #header {
        color: $main-green;
        margin-bottom: 1rem;
        font-size: 20px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .camp-cards {
      height: 50vh;
      text-align: center;
      max-width: 100vw;
      max-height: 50vh;
      min-height: -webkit-fill-available;

      #button {
        color: $white;
        top: 14rem;
      }

      img {
        height: 30vh;
        width: 15rem;
        object-fit: cover;
      }
    }
  }
}
</style>
