<template>
  <sui-form id="form" @submit.prevent="handleSubmit">
    <sui-form-field v-show="isRegister">
      <label>Nome *</label>
      <input v-model="userInfo.name" label="Nome" placeholder="Nome" />
    </sui-form-field>
    <sui-form-field>
      <label>Email <span v-show="isRegister">*</span></label>
      <input
        v-model="userInfo.email"
        label="Email"
        required
        type="email"
        placeholder="Email"
        autocomplete="on"
      />
    </sui-form-field>
    <sui-form-field>
      <label>Senha <span v-show="isRegister">*</span></label>
      <label v-show="caps" class="caps-lock">O caps lock está ativado!</label>
      <div id="password-container">
        <input
          v-model="userInfo.password"
          :type="seePassword ? 'text' : 'password'"
          label="Senha"
          required
          placeholder="Senha"
          autocomplete="on"
          ref="password"
          @keydown="handleKeyDown"
        />
        <sui-icon
          name="eye"
          class="toggle-password"
          size="large"
          :color="seePassword ? 'grey' : 'black'"
          @click="seePassword = !seePassword"
        />
      </div>
    </sui-form-field>
    <sui-form-field v-show="isRegister">
      <label>Cpf</label>
      <input
        v-mask="'###.###.###-##'"
        v-model="cpf"
        placeholder="000.000.000-00"
      />
    </sui-form-field>
    <sui-form-field v-show="isRegister">
      <label>Data de Nascimento</label>
      <input
        v-mask="'##/##/####'"
        v-model="bornDate"
        placeholder="dd/mm/aaaa"
      />
    </sui-form-field>
    <sui-form-field v-show="isRegister">
      <label>Telefone</label>
      <input
        v-mask="'(##) #####-####'"
        v-model="phoneNumber"
        placeholder="(85)91234-5678"
      />
    </sui-form-field>
    <router-link :to="`/redefinir-senha?email=${userInfo.email}`">
      Esqueceu sua senha?
    </router-link>
    <br />
    <button type="submit" class="submit-button">
      <span v-if="!loading">{{ buttonText }}</span>
      <sui-icon name="notched circle" loading v-else />
    </button>

    <sui-segment aligned="center" v-show="!isRegister">
      <sui-divider horizontal>Ou faça login com</sui-divider>

      <button class="social-login" @click="loginWithGoogle" type="button">
        <img src="../assets/images/google.svg" alt="Icone do Google" />
        Google
      </button>
    </sui-segment>
  </sui-form>
</template>

<script>
import { mapActions } from "vuex";

import { api } from "../services/apiClient";

export default {
  name: "AuthForm",
  data: () => ({
    userInfo: {
      email: "",
      password: "",
    },
    bornDate: "",
    phoneNumber: "",
    cpf:"",
    caps: false,
    seePassword: false,
  }),
  props: {
    isRegister: { type: Boolean, default: false },
    buttonText: String,
    submitForm: Function,
    loading: Boolean,
  },
  methods: {
    handleDate() {
      if (!this.bornDate) return false;

      const [day, month, year] = this.bornDate.split("/");

      const thisYear = new Date().getFullYear();

      if (year < 1000 || year > thisYear || month == 0 || month > 12) {
        this.$notify({ text: "Insira uma data válida!", type: "error" });
        return false;
      }

      const monthsLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthsLength[1] = 29;

      if (!(day > 0 && day <= monthsLength[month - 1])) {
        this.$notify({ text: "Insira uma data válida!", type: "error" });
        return false;
      }

      this.userInfo.born_date = `${year}-${month}-${day}`;

      return true;
    },
    handlePhoneNumber() {
      if (!this.phoneNumber) return;

      const replaceRegex = /[()-\s]/gi;

      const replacedPhone = this.phoneNumber.replace(replaceRegex, "");

      this.userInfo.phone_number = replacedPhone;
    },
    handleKeyDown(e) {
      this.caps = !!e.getModifierState("CapsLock");
    },
    handleSubmit() {
      if (this.isRegister) {
        if (!this.handleDate()) return;
        this.handlePhoneNumber();
      }

      this.$emit("submit-form", this.userInfo);
    },
    async loginWithGoogle() {
      this.$gAuth
        .signIn()
        .then((googleUser) => {
          const data = {
            email: googleUser.getBasicProfile().getEmail(),
            name: googleUser.getBasicProfile().getName(),
            loginHint: googleUser.getAuthResponse().login_hint,
          };

          api.post("auth/oauth/google", data).then(async (res) => {
            const { token, refreshToken, user } = res.data;
            this.setAuth({ token, refreshToken, user });

            if (this.$store.state.auth.user.admin) this.$router.push("/admin");
            else if (this.$store.getters.checkReservation()) {
              const data = {
                reservation_date:
                  this.$store.state.reservations.reservation.date,
                enter_time: this.$store.state.reservations.reservation.time,
                sport: this.$store.state.reservations.reservation.sport,
              };
              await this.createReservation(data);
            } else if (this.$store.getters.getHours()) {
              this.$router.push("/pagamento");
            } else this.$router.push("/minhas-reservas");
          });
        })
        .catch(() => {});
    },
    ...mapActions(["setAuth", "createReservation"]),
  },
};
</script>

<style lang="scss" scoped>
#form {
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;

  .caps-lock {
    color: red;
  }

  #password-container {
    display: flex;
    align-items: center;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;

    input {
      border: 0;
    }

    .toggle-password {
      cursor: pointer;
      margin-right: 1rem;
    }
  }

  .submit-button {
    @include button;
    background: $main-green;
    color: $white;
    padding: 0.5rem 3rem !important;
    margin-top: 1rem;

    &.forgot {
      background: $white;
      color: $main-green;
      border: 1px solid $main-green;
    }
  }

  .social-login {
    @include button;
    display: flex;
    align-items: center;
    font: 400 18px "Roboto", sans-serif !important;
    display: inline-block;

    img {
      margin-right: 1rem;
    }
  }
}
</style>
