<template>
  <div id="landing">
    <swiper :options="swiperOptions" class="carousel">
      <swiper-slide :key="index" v-for="index in 14">
        <img
          :src="require(`../assets/images/carousel/carrossel_${index}.png`)"
          :alt="`Carrossel fotos praticando esportes ${index}`"
        />
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>

    <div class="reservations">
      <h1>Reserve já!</h1>
      <section>
        <ReservationHomeCard
          icon="field"
          title="Faça sua reserva online"
          text="Pelo nosso site, você pode realizar sua reserva de forma totalmente online, escolhendo o horário desejado, com um pagamento totalmente seguro. É rápido e fácil!"
        />
        <div class="divider"></div>
        <ReservationHomeCard
          icon="credit_card"
          title="Escolha a forma de pagamento"
          text="Nossa aplicação aceita pagamentos via cartão de crédito, saldo em conta do Mercado Pago e até mesmo Pix!"
        />
      </section>
      <button @click="$router.push('/reservas')" class="goToReservations">
        Reserve aqui!
      </button>
    </div>

    <Section title="Confira os nossos preços!" :gridType="1">
      <PriceCard title="Hora avulsa" price="60" isMainButton />
      <PriceCard title="Pacote 3 horas" price="150" costPerHour="50,00" />
      <PriceCard title="Pacote 8 horas" price="320" costPerHour="40,00" />
      <PriceCard title="Pacote 16 horas" price="600" costPerHour="37,50" />
    </Section>

    <div class="store">
      <div class="sports">
        <ul>
          <li><sui-icon name="angle right" />Vôlei de praia</li>
          <li><sui-icon name="angle right" />Beach Tennis</li>
          <li><sui-icon name="angle right" />Futevôlei</li>
        </ul>
      </div>
      <div class="store-details">
        <p>
          Incrições em eventos <br />
          <span> e venda de produtos</span>
        </p>
        <a
          href="https://loja.arenamm.com.br"
          target="_blank"
          rel="noopener noreferrer"
          >Confira nossa loja</a
        >
        <img src="../assets/images/products_store.png" alt="Produtos da loja" />
      </div>
    </div>

    <div class="tournament">
      <div id="celebration">
        <img :src="require(`../assets/images/celebration.png`)" id="group" />
      </div>
      <div id="tour-button">
        <button
          @click="$router.push('/campeonatos')"
          class="goToTournment"
          style="font-size: 25px"
        >
          Confira os campeonatos
        </button>
      </div>
      <div id="diamond-group">
        <img
          :src="require(`../assets/images/pictures_diamond.png`)"
          id="diamond"
        />
      </div>
    </div>

    <Section title="Nossa localização" :gridType="3" class="map-section">
      <GmapMap
        :center="{ lat: -3.783237, lng: -38.477714 }"
        :zoom="15.5"
        map-type-id="terrain"
        :options="gmapOptions"
        class="map"
      >
        <GmapMarker :position="{ lat: -3.783237, lng: -38.477714 }" />
      </GmapMap>
      <p>
        R. Nestor Fontenele Vasconcelos, 353 - Edson Queiroz, Fortaleza - CE,
        60834-355
      </p>
    </Section>

    <Section title="Fale conosco" :gridType="2">
      <ContactIcon icon="instagram" title="Instagram" text="@mm_arena_" />
      <ContactIcon
        icon="envelope outline"
        title="E-mail"
        text="contato@falcodev.com.br"
      />
      <ContactIcon icon="whatsapp" title="WhatsApp" text="(85) 99962-7015" />
    </Section>

    <div class="newsletter">
      <h1>Newsletter</h1>
      <p>
        Quer ficar por dentro das novidades? É só cadastrar seu e-mail e pronto.
      </p>
      <form @submit.prevent="subscribeToNewsletter">
        <input placeholder="Nome" required v-model="newsletter.name" />
        <input
          placeholder="Email"
          type="email"
          required
          v-model="newsletter.email"
        />
        <button type="submit">
          <span v-if="!newsletter.loading">Se inscrever</span>
          <sui-icon name="notched circle" loading v-else />
        </button>
      </form>
    </div>

    <Footer />
  </div>
</template>

<script>
import { api } from "../services/apiClient";

import ReservationHomeCard from "../components/Home/ReservationHomeCard.vue";
import Section from "../components/Home/Section.vue";
import PriceCard from "../components/Home/PriceCard.vue";
import ContactIcon from "../components/Home/ContactIcon.vue";

import Footer from "../components/Footer.vue";

export default {
  components: {
    ReservationHomeCard,
    Section,
    PriceCard,
    ContactIcon,
    Footer,
  },
  data: () => ({
    swiperOptions: {
      slidesPerView: 3,
      spaceBetween: 0,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1280: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 2,
        },
        200: {
          slidesPerView: 1,
        },
      },
    },
    gmapOptions: {
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    },
    newsletter: {
      name: null,
      email: null,
      loading: false,
    },
  }),
  methods: {
    async subscribeToNewsletter() {
      this.newsletter.loading = true;
      const newsletterData = {
        name: this.newsletter.name,
        email: this.newsletter.email,
      };
      try {
        const { data } = await api.post(
          "integration/subscribe",
          newsletterData
        );
        this.$notify({ text: data.message, type: "success" });
      } catch {
        /* eslint-disable no-empty */
      }
      this.newsletter.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#landing {
  background: $light-black url(../assets/images/background.png) no-repeat center
    top;
  max-width: 100vw;

  @media (max-width: 1440px) {
    background: $light-black url(../assets/images/background_responsive.png)
      no-repeat center top;
  }

  h1 {
    color: $white;
    font-size: 3rem;
  }

  .carousel {
    padding-top: 112px;
    height: 100vh;
    text-align: center;
    max-width: 100vw;
    max-height: 100vh;
    min-height: -webkit-fill-available;

    img {
      height: 100vh;
    }
  }

  .reservations {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 0, 0, 0.8) url(../assets/images/reservas_section.png)
      no-repeat;
    background-size: cover;
    background-position: center;
    padding: 2rem 0;

    section {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      width: 100%;

      .divider {
        height: 16rem;
        width: 0.2rem;
        background: $white;
        margin: 0;
        margin: 0 8rem;
      }

      @media (max-width: 960px) {
        flex-direction: column;
        align-items: center;

        > * {
          margin: 2rem 0;
        }

        .divider {
          display: none;
        }
      }
    }

    .goToReservations {
      @include button;
      background: $main-green;
      color: $white;
      margin-top: 2rem;
    }

    @media (max-width: 1440px) {
      h1 {
        font-size: 36px;
      }
    }
  }

  .tournament {
    background: $light-black url(../assets/images/tournament_backgroud.png);
    height: 45rem;
    margin-top: 2rem;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    text-align: center;

    div {
      width: 33%;
      padding: 5px;
    }

    #tour-button {
      margin-top: 32rem;

      .goToTournment {
        @include button;
        background: $main-green;
        color: $white;

        h1 {
          font-size: 40px;
        }
      }
    }
    #diamond-group {
      #diamond {
        width: 25vw;
        min-width: 29rem;
        margin-left: -2rem;
        margin-top: 2rem;
      }
    }
    #celebration {
      margin-top: 7rem;
      #group {
        width: 25vw;
        min-width: 22rem;
        margin-bottom: 15rem;
        margin-left: 3vw;
      }
    }
    @media (max-width: 960px) {
      display: block;

      height: auto;

      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
      margin-top: -7rem;

      #group {
        display: none;
      }
      #tour-button {
        margin: auto;
        width: 60%;
        padding: 10px;
        .goToTournment {
          width: 21rem;
        }
      }
      #diamond-group {
        margin: auto;
        width: 100%;
        margin-left: 0;

        #diamond {
          width: 65vw;
          min-width: 315px;
          min-width: 35rem;
          max-height: 34rem;
        }

        margin-bottom: 10vh;
        @media (max-width: 500px) {
          #diamond-group {
            #diamond {
              width: 15rem;
            }
          }
        }
      }
    }
  }

  .store {
    background: $light-black url(../assets/images/store_background.png);
    background-position: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    margin-top: 6rem;

    @media (max-width: 960px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
    }

    .sports {
      margin-left: 6rem;

      ul {
        list-style-type: none;

        li {
          font-size: 36px;
          color: $white;
          padding: 2rem 0;
          font-weight: 900;
          font-style: italic;

          & + li {
            margin-left: 6rem;

            & + li {
              margin-left: 15rem;
            }
          }
        }
      }

      @media (max-width: 1440px) {
        li {
          font-size: 28px !important;
        }
      }

      @media (max-width: 960px) {
        margin: 0;
        background: $light-black
          url(../assets/images/store_background_mobile.png);
        width: 100%;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          margin: 0 !important;
        }
      }
    }

    .store-details {
      margin-left: 10rem;
      padding: 4rem 0;

      display: flex;
      flex-direction: column;

      p {
        color: $white;
        font-size: 36px;
        font-weight: 900;
        font-style: italic;

        span {
          margin-left: 4rem;
        }
      }

      a {
        @include button;
        background: $main-green;
        color: $white;
        align-self: flex-start;
        margin-left: 10rem;
      }

      img {
        width: 20rem;
        margin-left: 10rem;
        margin-top: 2rem;
      }

      @media (max-width: 1440px) {
        p {
          font-size: 28px;
        }
      }

      @media (max-width: 960px) {
        margin: 0;
        align-items: center;
        justify-content: center;

        span,
        a,
        img {
          margin: 0 !important;
          align-self: center;
        }
      }
    }
  }

  .map-section {
    .map {
      width: 700px;
      height: 400px;

      @media (max-width: 968px) {
        width: 300px;
        height: 200px;
      }
    }

    p {
      color: $white;

      @media (max-width: 968px) {
        font-size: 14px;
        max-width: 80%;
        text-align: center;
      }
    }

    @media (max-width: 1440px) {
      h1 {
        font-size: 36px;
      }
    }
  }

  .newsletter {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    color: $white;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
    }

    form {
      margin: 0;
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        @include input;
        margin: 0 2rem;
      }

      button {
        @include button;
        font-size: 1.25rem;
        background: $main-green;
        color: $white;
      }
    }

    @media (max-width: 1440px) {
      h1 {
        font-size: 36px;
      }

      p {
        font-size: 16px;
      }
    }

    @media (max-width: 1048px) {
      form {
        flex-direction: column;
        align-items: center;

        > * {
          margin-top: 1rem;
        }

        input {
          margin: 1rem 0;
        }

        button {
          font-size: 1rem;
          width: 20rem;
        }
      }
    }
  }
}
</style>
