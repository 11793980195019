export const state = () => ({
  reservation: {
    date: null,
    time: null,
    sport: null,
  },
  hours: null,
  cardForm: null,
});

export default state;
