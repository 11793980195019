<template>
  <div id="my-reservations">
    <h1>Meu Perfil</h1>

    <!-- <h2 v-show="packageTime">
      Horas disponíveis: <span>{{ packageTime }}</span>
    </h2> -->

    <sui-loader v-if="loading" active centered inline class="not-reservation" />

    <div
      v-else-if="!loading && perfis.length == 0"
      class="not-reservation"
    >
      Você não possui perfil ativo {{ this.$store.state.auth.user.id}}
    </div>

    <div class="reservations-cards" v-else>
      <PerfilCard
        v-for="perfil in perfis"
        :key="perfil.id"
        :id="perfil.id"
        :userName="perfil.userName"
        :phone="perfil.phone"
        :niver="perfil.niver"
      />
    </div>
  </div>
</template>

<script>
import { api } from "../../services/apiClient";


 import { formatPhoneNumber, formatDate } from "../../helpers/format";

import PerfilCard from "../../components/User/PerfilCard.vue";

export default {
  components: {
    PerfilCard,
  },
  data: () => ({
    perfis: [],
    loading: false,
  }),
  methods: {
    async fetchPerfis() {
      this.loading = true;
// de alguma maneira ele sabe qual usuario tem que buscar
      const { data } = await api.get("reservations/user");

      // const sports = {
      //   beach_tennis: "Beach Tênis",
      //   volley: "Vôlei de Praia",
      //   footvolley: "Futevôlei",
      // };
// se consegue pegar os dados do usuario, usando o state.auth, sem ter que chamar a api.get
      const user = this.$store.state.auth.user.name;
      const bornDate = formatDate(this.$store.state.auth.user.born_date);
      const phone = formatPhoneNumber(this.$store.state.auth.user.phone_number);


      // this.reservations = data.map((reservation) => {
      //   const resDate = formatDate(reservation.reservation_date);
      //   const resCreatedAt = formatDate(reservation.createdAt);
      //   const resTime = formatTime(reservation.enter_time);
      //   const resTimeLeave = formatTime(reservation.leave_time);

      //   return {
      //     id: reservation._id,
      //     court: reservation.court.name,
      //     enterTime: resTime,
      //     leaveTime: resTimeLeave,
      //     date: resDate,
      //     createdAt: resCreatedAt,
      //     confirmed: reservation.confirmed,
      //     sport: sports[reservation.sport],
      //     userName: user,
      //
      //   };
      // });
      this.perfis = data.map((perfil) => {
        // const BornDate = formatDate(perfil.born_date);

        return {
              id: perfil._id,
              court: perfil.court.name,
              // enterTime: resTime,
              // leaveTime: resTimeLeave,
              // date: resDate,
              // createdAt: resCreatedAt,
              confirmed: perfil.confirmed,
              // sport: sports[per.sport],
              userName: user,
              phone: phone,
        //   id:perfil._id,
        //   email: perfil.email,
            niver:bornDate,
        //   nome: user,
        };
      });

      this.loading = false;
    },
    // async fetchPackages() {
    //   const res = await api.get("package");
    //   this.packageTime = res.data.hoursLeft;
    // },
  },
  async mounted() {
    await this.fetchPerfis();
    // await this.fetchPackages();
  },
};
</script>

<style lang="scss" scoped>
#my-reservations {
  padding-top: 8rem;
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    align-self: flex-start;
    font: 400 24px "Roboto";

    span {
      font-weight: bold;
    }
  }

  .not-reservation {
    margin-top: 2rem;
  }

  .reservations-cards {
    margin: auto;
    display: flex;


  }
}
</style>
