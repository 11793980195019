import Reservations from "../pages/User/Reservations";
import Payment from "../pages/User/Payment";
import PerfilCard from "../pages/User/PerfilCard";

export default [
  {
    path: "/minhas-reservas",
    name: "my-reservations",
    component: Reservations,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pagamento",
    name: "Pagamento",
    component: Payment,
    meta: {
      requiresAuth: true,
    },

  },
  {
    path: "/perfil",
    name: "PerfilCard",
    component: PerfilCard,
    meta: {
      requiresAuth: true,
    },
  },
];
