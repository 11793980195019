<template>
  <div id="admin-reservation">
    <sui-table-row>
      <sui-table-cell>{{ user }}</sui-table-cell>
      <sui-table-cell>{{ court }}</sui-table-cell>
      <sui-table-cell> {{ enterTime }} às {{ leaveTime }} </sui-table-cell>
      <sui-table-cell>{{ createdAt }}</sui-table-cell>
      <sui-table-cell>{{ obs }}</sui-table-cell>
      <sui-table-cell>{{ phoneNumber }}</sui-table-cell>
      <sui-table-cell class="payment" collapsing>
        <div :class="['pay-label', confirmed ? 'green' : 'red']">
          {{ confirmed ? "Pago" : "Não pago" }}
        </div>
        <sui-icon
          name="caret down"
          :class="['arrow', collapsed ? 'upside-down' : '']"
          v-show="!confirmed"
          @click="collapsed = !collapsed"
        />
      </sui-table-cell>
    </sui-table-row>
    <sui-table-row
      :class="['lower', collapsed ? 'collapse' : '']"
      v-show="collapsed && !confirmed"
    >
      <sui-table-cell v-for="index in 5" :key="index"></sui-table-cell>
      <sui-table-cell collapsing>
        <button class="red" @click="removeReservation">
          <sui-icon name="trash" />
        </button>
        <button class="green" @click="confirmReservation">
          <sui-icon name="check" />
        </button>
      </sui-table-cell>
    </sui-table-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { api } from "../../services/apiClient";

export default {
  props: {
    user: String,
    court: String,
    enterTime: String,
    leaveTime: String,
    createdAt: String,
    confirmed: Boolean,
    id: String,
    obs: String,
    phoneNumber: String,
  },
  data: () => ({
    collapsed: false,
  }),
  methods: {
    async removeReservation() {
      const res = await this.deleteReservation(this.id);
      if (res) this.$notify({ title: res.message, type: "success" });

      this.$emit("deleted", this.id);
    },
    async confirmReservation() {
      const res = await api.put(`reservation/${this.id}`);
      if (res) this.$notify({ title: res.data.message, type: "success" });
      this.$emit("confirmed", this.id);
    },
    ...mapActions(["deleteReservation"]),
  },
};
</script>

<style lang="scss" scoped>
#admin-reservation {
  display: contents;

  .payment {
    display: flex;
    align-items: center;

    .pay-label {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      color: $white;
      border-radius: 0.8rem;
      margin-right: 0.5rem;
    }

    .arrow {
      cursor: pointer;
      transition: all 0.3s;
    }

    .upside-down {
      transform: rotate(180deg);
    }
  }

  .lower {
    button {
      @include button;
      color: $white;
      padding: 0.5rem 1rem;
      margin: 0.5rem;

      .icon {
        margin: 0;
      }
    }
  }
}

.red {
  background: $red;
}

.green {
  background: $main-green;
}
</style>
