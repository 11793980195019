import Vue from "vue";
import Router from "vue-router";

import store from "../store";

import publicRoutes from "./public.routes";
import userRoutes from "./user.routes";
import adminRoutes from "./admin.routes";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [...publicRoutes, ...userRoutes, ...adminRoutes],
});

router.beforeEach((to, from, next) => {
  const auth = store.state.auth;

  to.matched.some((record) => {
    if (record.meta.requiresAuth) {
      if (record.meta.requiresAdmin && !auth.user.admin) next("/");
      if (auth.isAuthenticated) next();
      else next("/");
    } else next();
  });

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.auth.isAuthenticated) next();
    else next("/");
  } else next();
});

export default router;
