<template>
  <div id="section">
    <h1>{{ title }}</h1>
    <div
      :class="[
        'cards',
        gridType == 1 ? 'grid-1' : gridType == 2 ? 'grid-2' : 'grid-3',
      ]"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: {
    title: String,
    gridType: Number,
  },
};
</script>

<style lang="scss" scoped>
#section {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: $white;
    font-size: 3rem;
    text-align: center;
  }

  @media (max-width: 1440px) {
    margin-top: 2rem;

    h1 {
      font-size: 36px;
    }
  }

  .cards {
    display: grid;
    margin-top: 4rem;
    grid-template-columns: repeat(1, 1fr);

    &.grid-1 {
      grid-template-columns: repeat(4, 1fr);
      row-gap: 2rem;

      @media (max-width: 1279px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 960px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &.grid-2 {
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: 960px) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 4rem;
      }
    }

    &.grid-3 {
      justify-items: center;
    }
  }
}
</style>
