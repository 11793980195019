<template>
  <div id="reservations">
    <div class="header">
      <h1>Reservas {{ isToday ? "de hoje" : "do dia" }} ({{ thisDate }})</h1>
      <vc-date-picker
        class="inline-block h-full"
        v-model="inputDate"
        color="teal"
      >
        <template v-slot="{ togglePopover }">
          <div class="flex items-center">
            <p @click="togglePopover">Ver outras datas</p>
          </div>
        </template>
      </vc-date-picker>
    </div>
    <sui-table selectable>
      <sui-table-header>
        <sui-table-row>
          <sui-table-header-cell>Cliente</sui-table-header-cell>
          <sui-table-header-cell>Quadra</sui-table-header-cell>
          <sui-table-header-cell>Horário</sui-table-header-cell>
          <sui-table-header-cell>Reserva feita em</sui-table-header-cell>
          <sui-table-header-cell>Observações</sui-table-header-cell>
          <sui-table-header-cell>Telefone</sui-table-header-cell>
          <sui-table-header-cell>Status</sui-table-header-cell>
        </sui-table-row>
      </sui-table-header>
      <sui-table-body>
        <AdminReservation
          v-for="reservation in reservations"
          :key="reservation.id"
          :user="reservation.user"
          :court="reservation.court"
          :enterTime="reservation.enterTime"
          :leaveTime="reservation.leaveTime"
          :createdAt="reservation.createdAt"
          :confirmed="reservation.confirmed"
          :id="reservation.id"
          :obs="reservation.obs"
          :phoneNumber="reservation.phoneNumber"
          @deleted="setDeleted"
          @confirmed="setConfirmed"
        />
      </sui-table-body>
    </sui-table>
  </div>
</template>

<script>
import { useQuery } from "vue-query";

import { api } from "../../services/apiClient";

import AdminReservation from "../../components/Admin/AdminReservation.vue";

import {
  formatDate,
  formatTime,
  formatPhoneNumber,
} from "../../helpers/format";

let date = new Date();
date = date.toISOString();

const fetchReservations = async () => {
  const res = await api.get("reservation", {
    params: { date: date.split("T")[0] },
  });

  const reservations = res.data.map((reservation) => {
    return {
      id: reservation._id,
      user: reservation.user.name,
      court: reservation.court.name,
      enterTime: formatTime(reservation.enter_time),
      leaveTime: formatTime(reservation.leave_time),
      createdAt: formatDate(reservation.createdAt),
      confirmed: reservation.confirmed,
      obs: reservation.obs,
      phoneNumber: formatPhoneNumber(reservation.user.phone_number),
    };
  });

  return reservations;
};

export default {
  setup() {
    const { data } = useQuery("reservations", () => fetchReservations(), {
      staleTime: 1000 * 60, // one minute
      refetchInterval: 1000 * 60 * 10, // 10 minutes
    });

    return {
      reservations: data,
    };
  },
  components: {
    AdminReservation,
  },
  data: () => ({
    thisDate: "",
    inputDate: "",
    isToday: true,
  }),
  mounted() {
    let thisDate = new Date();
    thisDate = thisDate.toISOString();
    this.thisDate = formatDate(thisDate);
  },
  methods: {
    setDeleted(id) {
      const index = this.reservations.findIndex((res) => res._id == id);
      this.reservations.splice(index, 1);
    },
    setConfirmed(id) {
      const index = this.reservations.findIndex((res) => res._id == id);
      this.reservations[index].confirmed = true;
    },
  },
  watch: {
    async inputDate(newDate) {
      newDate = newDate.toISOString();
      date = newDate;
      this.thisDate = formatDate(newDate);
      this.isToday = false;
      this.reservations = await fetchReservations();
    },
    reservations(newValue, oldValue) {
      if (oldValue) {
        const notificationSound = new Audio(
          require("../../assets/sounds/notification.wav")
        );
        notificationSound.play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#reservations {
  padding-top: 8rem;
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;

    h1 {
      margin: 0;
    }

    p {
      cursor: pointer;
      color: $darker-green;
    }
  }

  .reservations-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
</style>
