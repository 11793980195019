<template>
  <div
    id="reservation"
    :class="[
      reservation.status == 'Disponível' ? 'available' : 'unavailable',
      { selected: reservation.status == 'Selecionado' },
    ]"
    @click="selectTime(reservation)"
  >
    <p>
      {{ time(reservation.enter_time) }} às
      {{ time(reservation.leave_time) }}
    </p>
    <p>{{ reservation.status }}</p>
  </div>
</template>

<script>
import { formatTime } from "../../helpers/format";

export default {
  props: {
    reservation: Object,
  },
  methods: {
    selectTime(reservation) {
      if (reservation.status == "Disponível")
        this.$emit("select-time", reservation);
      else if (reservation.status == "Selecionado")
        this.$emit("unselect-time", reservation);
    },
    time(time) {
      return formatTime(time);
    },
  },
};
</script>

<style lang="scss" scoped>
#reservation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  margin-top: 0.25rem;
  font-weight: 300;

  &.available {
    background: $light-green;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: $light-green-hover;
    }
  }

  &.unavailable {
    background: $grey-green;
    color: $grey;
  }

  &.selected {
    background: $dark-green;
    color: $white;
    font-weight: 500;
    cursor: pointer;
  }
}
</style>
