import Home from "../pages/Home";
import Reservations from "../pages/Reservations";
import Auth from "../pages/Auth";
import TermsOfUse from "../pages/TermsOfUse";
import Packages from "../pages/Packages";
import RedefinePassword from "../pages/RedefinePassword";
import Tournament from "../pages/Tournament";

export default [
  { path: "/", name: "Home", component: Home },
  { path: "/reservas", name: "Reservations", component: Reservations },
  { path: "/auth", name: "Auth", component: Auth },
  { path: "/pacotes", name: "Packages", component: Packages },
  { path: "/campeonatos", name: "Tournament", component: Tournament },
  {
    path: "/politica-de-privacidade-e-termos-de-uso",
    name: "TermsOfUse",
    component: TermsOfUse,
  },
  {
    path: "/redefinir-senha",
    name: "RedefinePassword",
    component: RedefinePassword,
  },
];
