<template>
  <div id="redefine">
    <sui-segment id="segment" aligned="center">
      <sui-form>
        <sui-form-field v-show="current == 0" id="forms">
          <label
            >Digite seu e-mail e lhe enviaremos um código para redefinição de
            sua senha.</label
          >
          <input
            v-model="email"
            label="Email"
            required
            type="email"
            placeholder="Email"
            autocomplete="on"
          />
          <sui-button
            type="submit"
            @click.prevent="sendEmail"
            label="Email"
            content="Enviar"
          >
          </sui-button>
          <div id="path">
            <div id="circulo" style="border-style: solid">1</div>
            <hr size="10" width="100rem" />
            <div id="circulo">2</div>
            <hr size="10" width="100rem" />
            <div id="circulo">3</div>
          </div>
        </sui-form-field>
      </sui-form>
      <sui-form>
        <sui-form-field v-show="current == 1" id="forms">
          <label>Digite o código que foi enviado.</label>
          <input
            v-model="code"
            label="Code"
            required
            type="text"
            placeholder="Código"
            autocomplete="off"
          />
          <sui-button
            type="submit"
            @click.prevent="checkCode"
            content="Seguinte"
          >
          </sui-button>
          <div id="path">
            <div id="circulo">1</div>
            <hr size="10" width="100rem" />
            <div id="circulo" style="border-style: solid">2</div>
            <hr size="10" width="100rem" />
            <div id="circulo">3</div>
          </div>
        </sui-form-field>
      </sui-form>
      <sui-form>
        <sui-form-field v-show="current == 2" id="forms">
          <label>Redefinir senha.</label>
          <label v-show="caps" class="caps-lock"
            >O caps lock está ativado!</label
          >
          <div id="password-container">
            <input
              v-model="newPassword"
              label="Code"
              required
              :type="seePassword ? 'text' : 'password'"
              placeholder="Nova Senha"
              autocomplete="off"
              @keydown="handleKeyDown"
            />
            <sui-icon
              name="eye"
              class="toggle-password"
              size="large"
              :color="seePassword ? 'grey' : 'black'"
              @click="seePassword = !seePassword"
            />
          </div>
          <sui-button
            type="submit"
            @click.prevent="redefinePassword"
            content="Submeter senha"
            :disabled="buttonStatus != true"
          >
          </sui-button>
          <div id="path">
            <div id="circulo">1</div>
            <hr size="10" width="100rem" />
            <div id="circulo">2</div>
            <hr size="10" width="100rem" />
            <div id="circulo" style="border-style: solid">3</div>
          </div>
        </sui-form-field>
      </sui-form>
    </sui-segment>
  </div>
</template>

<script>
import { api } from "../services/apiClient";
import router from "../routes";

export default {
  data: () => ({
    current: 0,
    email: null,
    code: null,
    newPassword: null,
    caps: false,
    seePassword: false,
    buttonStatus: false,
  }),

  mounted() {
    this.email = this.$route.query.email;
  },
  watch: {
    async newPassword(np) {
      if (np.length > 5) {
        this.buttonStatus = true;
      } else {
        this.buttonStatus = false;
      }
    },
  },
  methods: {
    async sendEmail() {
      await api.put("user/email-code", {
        email: this.email,
      });
      this.nextStep();
    },
    async checkCode() {
      try {
        await api.put("user/check", {
          email: this.email,
          code: this.code,
        });

        this.nextStep();
      } catch {
        this.code = null;
      }
    },
    async redefinePassword() {
      await api.put("user/redefine", {
        email: this.email,
        newPassword: this.newPassword,
      });
      this.current = 0;
      this.code = null;
      this.newPassword = null;
      router.push("/auth");
    },
    nextStep() {
      this.current += 1;
    },
    handleKeyDown(e) {
      this.caps = !!e.getModifierState("CapsLock");
    },
  },
};
</script>

<style lang="scss" scoped>
#redefine {
  transition: ease all 0.5s;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: 30vw;
    margin-left: -5vw;
  }

  #segment {
    width: 45vw;
    height: 45vh;
    align-items: center;
    justify-content: center;
    padding: 1rem 10rem 1rem 10rem;
  }

  #path {
    display: flex;
    margin-top: 2rem;
    margin-left: -3.75vw;
    font-size: 2.25rem;
    min-width: 0;

    #circulo {
      width: 5.125rem;
      height: 5.125rem;
      border-radius: 50%;
      background-color: $light-white;
      text-align: center;
      padding: 1rem;
      border-color: $main-green;
    }
    hr {
      margin-top: 2.7rem;
      border-top: 4px solid $light-white;
    }
  }

  #password-container {
    display: flex;
    align-items: center;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    background-color: $white;
    input {
      border: 0;
    }

    .toggle-password {
      cursor: pointer;
      margin-right: 1rem;
    }
  }

  .caps-lock {
    margin-bottom: 0rem;
    font-size: 1rem;
    color: red;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    font-size: 1.5rem;
    margin-left: -3.75vw;
  }
  #forms {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 2rem;
    width: 100%;
    min-width: 18rem;
  }
  button {
    @include button;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $main-green;
    color: $white;
    padding: 0.5rem 1rem;
    margin-top: 3rem;
    margin-left: -3.75vw;
  }

  @media (max-width: 482px) {
    #segment {
      width: 85vw;
      height: 45vh;
    }
    #forms {
      width: 100%;
      min-width: 85vw;
      margin-left: -30vw;
      margin-top: 2rem;
    }
    label {
      font-size: 1.3rem;
    }
    input {
      width: 60vw;
    }
    button {
      margin-left: -3.75vw;
    }
    #path {
      margin-top: 3rem;
      margin-left: -15rem;
      font-size: 1.5rem;
      margin-left: -3.75vw;

      #circulo {
        width: 3.5rem;
        height: 3.5rem;
        padding: 0.75rem;
      }
      hr {
        margin-top: 1.5rem;
      }
    }
  }
  @media (max-width: 768px) and (min-width: 483px) {
    #segment {
      width: 75vw;
      height: 45vh;
    }
    #forms {
      width: 100%;
      min-width: 50vw;
      margin-left: -3rem;
      margin-top: 3rem;
    }
    input {
      width: 50vw;
    }
    label {
      font-size: 1.3rem;
    }
    #path {
      margin-top: 2rem;
      margin-left: -15rem;
      font-size: 1.5rem;
      margin-left: -3.75vw;

      #circulo {
        width: 3rem;
        height: 3rem;
        padding: 0.5rem;
      }
      hr {
        margin-top: 1.5rem;
      }
    }
  }
  @media (max-width: 1205px) and (min-width: 769px) {
    #segment {
      width: 65vw;
      height: 45vh;
    }
    #forms {
      width: 100%;
      min-width: 50vw;
      margin-left: -4.5vw;
      margin-top: 3rem;
    }
    label {
      font-size: 1.3rem;
    }
    input {
      width: 50vw;
    }
    button {
      margin-left: -3.75vw;
    }
    #path {
      margin-top: 3rem;
      margin-left: -15rem;
      font-size: 1.5rem;
      margin-left: -3.75vw;

      #circulo {
        width: 3.5rem;
        height: 3.5rem;
        padding: 0.75rem;
      }
      hr {
        margin-top: 1.5rem;
      }
    }
  }
}
</style>
