<template>
  <sui-segment id="recurrence-picker">
    <sui-checkbox label="Reservar com recorrência" toggle v-model="checked" />

    <div v-show="checked" class="options">
      <sui-form>
        <sui-form-field>
          <label>Frequência</label>
          <sui-dropdown
            placeholder="Frequência"
            selection
            :options="frequencyOptions"
            v-model="recurrenceOptions.frequency"
          />
        </sui-form-field>
      </sui-form>

      <sui-checkbox
        :label="`Usar saldo total (${hoursLeft} hrs)`"
        toggle
        v-model="useAllHours"
        v-show="!admin"
      />

      <sui-form :class="useAllHours ? 'no-show' : 'show'">
        <sui-form-field>
          <label v-if="!admin">Saldo de {{ hoursLeft }} horas</label>
          <label v-else>Horas</label>

          <div class="input-hours">
            <input
              :type="admin ? 'number' : 'range'"
              :placeholder="hoursLeft"
              v-model="recurrenceOptions.amount"
              :max="hoursLeft"
              min="1"
            />
            <span v-show="!admin">{{ recurrenceOptions.amount }} horas</span>
          </div>
        </sui-form-field>
      </sui-form>
    </div>
  </sui-segment>
</template>

<script>
export default {
  name: "RecurrencePicker",
  props: ["hoursLeft", "admin"],
  data: () => ({
    checked: false,
    frequencyOptions: [
      { text: "Todo dia", value: 1 },
      { text: "Semanalmente", value: 7 },
      { text: "A cada 15 dias", value: 15 },
    ],
    recurrenceOptions: {
      frequency: 7,
      amount: null,
    },
    useAllHours: true,
  }),
  watch: {
    recurrenceOptions: {
      handler(options) {
        if (this.checked && options.frequency)
          this.$emit("get-recurrence", options);
      },
      deep: true,
    },
    hoursLeft(value) {
      this.recurrenceOptions.amount = value;
    },
    admin: {
      immediate: true,
      handler(value) {
        if (value) this.useAllHours = false;
      },
    },
    checked(value) {
      if (value) this.$emit("get-recurrence", this.recurrenceOptions);
      this.$emit("use-recurrence", value);
    },
  },
};
</script>

<style lang="scss" scoped>
#recurrence-picker {
  width: 50%;
  padding: 2rem;

  .options {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1rem;

    form {
      &.no-show {
        visibility: hidden;
      }

      .input-hours {
        display: flex;
        align-items: center;

        span {
          margin-left: 1rem;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .options {
      flex-direction: column;

      > * {
        margin: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
