<template>
  <div id="reservas">
    <div class="images">
      <img src="../assets/images/reservation_left.png" />
      <div class="pickers">
        <DatePicker @get-date="getDate" />
        <TimePicker :date="date" @get-time="getTime" :userHours="userHours" />
        <SportsPicker @get-sport="getSport" :shouldOpen="time.length >= 1" />
      </div>
      <img src="../assets/images/reservation_right.png" />
    </div>

    <sui-form
      v-show="$store.state.auth.user && $store.state.auth.user.admin"
      class="admin-form"
    >
      <sui-form-field>
        <label>Usuário</label>
        <sui-dropdown
          fluid
          placeholder="Atrelar reserva a um usuário, campo não obrigatório"
          selection
          :options="users"
          search
          v-model="user"
        />
      </sui-form-field>
      <sui-form-field>
        <label>Observações</label>
        <sui-input v-model="obs" />
      </sui-form-field>
    </sui-form>

    <RecurrencePicker
      v-show="
        $store.state.auth.isAuthenticated &&
        (userHours || $store.state.auth.user.admin) &&
        time.length <= 1
      "
      :hoursLeft="userHours"
      :admin="
        $store.state.auth.isAuthenticated ? $store.state.auth.user.admin : null
      "
      @get-recurrence="getRecurrence"
      @use-recurrence="useRecurrence"
    />

    <button
      :class="{ disabled: !date || !time || !sport }"
      @click="proceedWithReservation"
      :disabled="loading"
    >
      <p v-if="!loading">Reservar</p>
      <sui-icon v-else name="notched circle" loading />
    </button>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

import { api } from "../services/apiClient";

import DatePicker from "../components/Reservations/DatePicker.vue";
import TimePicker from "../components/Reservations/TimePicker.vue";
import SportsPicker from "../components/Reservations/SportsPicker.vue";

import RecurrencePicker from "../components/Reservations/RecurrencePicker.vue";

export default {
  components: {
    DatePicker,
    TimePicker,
    SportsPicker,
    RecurrencePicker,
  },
  data: () => ({
    notAvailableTimes: [],
    date: null,
    time: [],
    sport: null,
    users: [],
    user: null,
    obs: null,
    loading: false,
    userHours: null,
    recurrence: null,
  }),
  methods: {
    getDate(date) {
      this.date = date;
    },
    getTime(time) {
      this.time = time;
      if (time.length >= 2) this.recurrence = null;
    },
    getSport(sport) {
      this.sport = sport;
    },
    getRecurrence(recurrence) {
      this.recurrence = recurrence;
    },
    useRecurrence(use) {
      if (!use) this.recurrence = null;
    },
    async proceedWithReservation() {
      if (!this.date || this.time.length == 0 || !this.sport) return;

      this.loading = true;

      if (this.recurrence) {
        await this.recurrenceReservation();
        return;
      }

      if (
        this.$store.state.auth.isAuthenticated &&
        this.$store.state.auth.user.admin
      ) {
        await this.adminReservation();
        return;
      }

      const data = {
        reservation_date: this.date,
        enter_time: this.time,
        sport: this.sport,
      };

      this.setReservation({
        reservation_date: this.date,
        enter_time: this.time,
        sport: this.sport,
      });

      if (!this.$store.state.auth.isAuthenticated) this.$router.push("/auth");
      else await this.createReservation(data);
    },
    async adminReservation() {
      const data = {
        reservation_date: this.date,
        enter_time: this.time,
        sport: this.sport,
      };

      if (this.user) data["user"] = this.user;
      if (this.obs) data["obs"] = this.obs;

      const res = await api.post("/reservation/admin", data);
      if (res) {
        this.$notify({ text: "Reserva feita com sucesso!", type: "success" });
        this.$router.push("/admin");
      } else this.loading = false;
    },

    async recurrenceReservation() {
      const isAdmin = !!this.$store.state.auth.user.admin;
      const user = isAdmin ? this.user : null;

      const data = {
        reservation_date: this.date,
        enter_time: this.time,
        sport: this.sport,
        ...this.recurrence,
        admin: isAdmin,
        user,
      };

      const res = await api.post("/reservation/many", data);

      if (res) {
        this.$notify({ text: "Reserva feita com sucesso!", type: "success" });
        this.$router.push(isAdmin ? "/admin" : "/minhas-reservas");
      } else this.loading = false;
    },

    ...mapMutations(["setReservation"]),
    ...mapActions(["createReservation"]),
  },
  async mounted() {
    if (this.$store.state.auth.isAuthenticated) {
      if (this.$store.state.auth.user.admin) {
        // admin view
        const { data } = await api.get("users");
        data.map((user) => {
          this.users.push({
            key: user._id,
            text: `${user.name} | ${user.email}`,
            value: user._id,
          });
        });
      } else {
        // logged user view
        const { data } = await api.get("package");
        this.userHours = data.hoursLeft;
      }
    }
  },
  watch: {
    async user(value) {
      const { data } = await api.get("package", { params: { user: value } });
      this.userHours = data.hoursLeft;
    },
  },
};
</script>

<style lang="scss" scoped>
#reservas {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 2rem;

  .images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;

    img {
      width: 25%;
      height: 50%;
    }

    .pickers {
      width: 100%;
    }

    @media (max-width: 768px) {
      margin-top: 0;

      img {
        display: none;
      }
    }
  }

  button {
    @include button;
    background: $main-green;
    color: $white;
    margin: 2rem 0;

    &:hover {
      background: $darker-green;
    }

    &.disabled {
      background: $grey-green;
      cursor: default;
      pointer-events: none;
    }
  }

  .admin-form {
    width: 50%;
    margin-top: 2rem;
  }
}
</style>
