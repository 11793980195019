<template>
  <sui-segment id="reservation-card">
    <sui-label :color="confirmed ? 'teal' : 'red'" ribbon>
      {{ confirmed ? "Confirmado" : "Pagamento pendente" }}
    </sui-label>
    <h1>
      <sui-icon name="calendar alternate outline" />
      Nome:  {{ userName }}
    </h1>
    <span>
      <sui-icon name="map marker alternate" />
      {{ id }}
    </span>
    <br />
    <span>
      <sui-icon name="sort amount up" />
      Nível {{sport}}: {{sport}}
    </span>
    <br />
    <span>
      <sui-icon name="id card outline" />
      Cpf: {{sport}}: {{sport}}
    </span>
    <br />
    <span>
      <sui-icon name="birthday cake icon" />
      Data nascimento: {{niver}}
    </span>
    <br />
    <span>
      <sui-icon name="phone icon" />
      Telefone:{{phone}}
    </span>
    <br />

    <sui-divider />
    <p class="obs" v-show="!confirmed">
      <sui-icon name="question circle outline" />
    Gostaria de atualizar algum dado ?
      <a
        target="_blank"
        rel="noopener noreferrer"
        :href="`https://api.whatsapp.com/send?phone=5585999627015&text=Ol%C3%A1%2C%20realizei%20o%20pagamento%20de%20uma%20reserva%20mas%20ele%20ainda%20encontra-se%20pendente.%20Nome%3A%20${userName}%20Dia%3A%20${createdAt}%20Hor%C3%A1rio%3A%20${enterTime}`"
      >
        Entre em contato
      </a>
      com um funcionário
      <br/>
      <router-link :to="`/redefinir-senha?email=${userName.email}`">
      Trocar senha
    </router-link>
    <br />


    </p>
  </sui-segment>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Perfil",
  props: {
    niver: String,
    court: String,
     // confirmed: Boolean,
    // sport: String,
     userName: String,
     phone:String,
    id: String,
  },
  methods: {
    sendToReservation() {
      this.setHours(1);
      this.$router.push(`/pagamento?reservation=${this.id}`);
    },
    ...mapMutations(["setHours"]),
  },
};
</script>

<style lang="scss" scoped>
#reservation-card {
  margin: 0;
  padding-right: 4rem;

  h1 {
    font-size: 20px;
    margin: 0.8rem 0;
  }

  p {
    font-size: 16px;
    color: $grey;
    margin: 0;

    &.obs {
      font-size: 14px;

      a {
        cursor: pointer;
      }
    }
  }

  span {
    font-size: 18px;
  }
}
</style>
