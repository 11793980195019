<template>
  <div id="reservation-home-card">
    <header>
      <img :src="require(`../../assets/images/${icon}.svg`)" :alt="icon" />
      {{ title }}
    </header>
    <main>{{ text }}</main>
  </div>
</template>

<script>
export default {
  name: "ReservationHomeCard",
  props: {
    title: String,
    icon: String,
    text: String,
  },
};
</script>

<style lang="scss" scoped>
#reservation-home-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 22rem;

  color: $white;

  header {
    display: flex;
    align-items: center;

    img {
      height: 3rem;
      margin-right: 2rem;
    }

    font-weight: 700;
    font-size: 20px;
  }

  main {
    margin-top: 2rem;
  }

  @media (max-width: 1400px) {
    header {
      font-size: 18px;

      img {
        height: 2.5rem;
      }
    }

    main {
      font-size: 16px;
    }
  }
}
</style>
