<template>
  <nav :class="[scrolled ? 'active' : '', secondary ? 'secondary active' : '']">
    <router-link to="/" class="logo" @click.native="clickLink">
      <img src="../assets/images/logo.svg" alt="Logo da Arena MM" />
    </router-link>
    <sui-icon
      name="bars"
      class="hamburger"
      @click="active = !active"
      size="large"
      v-show="!secondary"
    />
    <div v-show="!secondary" :class="['menu', { 'is-open': active }]">
      <router-link
        @click.native="clickLink"
        to="/auth"
        class="nav-link"
        v-show="!$store.state.auth.isAuthenticated"
      >
        Login
      </router-link>
      <router-link
        @click.native="clickLink"
        to="/minhas-reservas"
        class="nav-link"
        v-show="
          $store.state.auth.isAuthenticated && !$store.state.auth.user.admin
        "
      >
        Ver suas reservas
      </router-link>
      <router-link
        @click.native="clickLink"
        to="/admin"
        class="nav-link"
        v-show="
          $store.state.auth.isAuthenticated && $store.state.auth.user.admin
        "
      >
        Ver reservas
      </router-link>

      <router-link
        @click.native="clickLink"
        to="/perfil"
        class="nav-link"
        v-show="
          $store.state.auth.isAuthenticated
        "
        >Perfil
      </router-link>
      <span
        class="nav-link"
        v-show="$store.state.auth.isAuthenticated"
        @click="logout"
      >
        Sair
      </span>
      <sui-divider class="divider" />
      <router-link to="/reservas" class="button" @click.native="clickLink">
        Faça já sua reserva!
      </router-link>
    </div>
  </nav>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

export default {
  name: "Navbar",
  props: {
    secondary: { type: Boolean, default: false },
  },
  data: () => ({
    scrolled: false,
    active: false,
  }),
  created() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 160) this.scrolled = true;
        else this.scrolled = false;
      });
    }
  },
  methods: {
    logout() {
      this.signOut(this.$route.path !== "/");
      this.removeReservation();
    },
    clickLink() {
      this.active = false;
    },
    ...mapMutations(["removeReservation"]),
    ...mapActions(["signOut"]),
  },
};
</script>

<style lang="scss" scoped>
nav {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background: $light-white;
  position: fixed;
  transition: all 0.3s ease-in;
  z-index: 1000;
  flex-wrap: wrap;

  .button {
    @include button;
    text-decoration: none;
    background: $main-green;
    color: $white;
  }

  .nav-link {
    text-decoration: none;
    color: $black;
    margin-right: 2rem;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      color: $main-green;
    }
  }

  .logo {
    display: flex;
    align-items: flex-end;
    text-decoration: none;

    img {
      width: 8rem;
    }
  }

  &.active {
    transition: all 0.3s ease-in;
    padding: 0.5rem 2rem;
    background: $white;

    .button {
      transition: all 0.3s ease-in;
      padding: 0.5rem 1rem;
      font-size: 16px;
    }

    .logo {
      img {
        transition: all 0.3s ease-in;
        width: 5rem;
      }
    }
  }

  &.secondary {
    background: $light-green;
    position: relative;
    // position: absolute;
  }

  .hamburger {
    cursor: pointer;
    display: none;
  }

  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .divider {
    display: none;
  }

  @media (max-width: 768px) {
    .hamburger {
      display: block;
    }

    .menu {
      overflow: hidden;
      flex-direction: column;
      align-items: center;
      width: 100%;
      transition: max-height 0.3s ease-in;
      max-height: 0;

      &.is-open {
        max-height: 20rem;
      }

      .button {
        margin-bottom: 1rem;
      }
    }

    .nav-link {
      margin: 2rem 0;
    }

    .divider {
      display: block;
      width: 100%;
    }
  }
}
</style>
