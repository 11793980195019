<template>
  <div id="date-picker">
    <ReservationTitle
      :showData="formattedDate"
      icon="calendar alternate outline"
      text="Escolha uma data"
      :extend="extend"
      @toggle-component="toggle"
    />

    <vc-date-picker
      v-show="!extend"
      color="teal"
      is-expanded
      is-required
      :min-date="new Date()"
      @dayclick="emitDate"
      :value="null"
    />
  </div>
</template>

<script>
import ReservationTitle from "./ReservationTitle.vue";

export default {
  name: "DatePicker",
  components: {
    ReservationTitle,
  },
  data: () => ({
    date: "",
    formattedDate: "",
    extend: false,
  }),
  methods: {
    toggle() {
      this.extend = !this.extend;
    },
    emitDate(date) {
      if (this.date != date.id) this.$emit("get-date", date.id);
      this.date = date.id;
      const [year, month, day] = date.id.split("-");
      this.formattedDate = `${day}/${month}/${year}`;
      this.extend = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#date-picker {
  width: 100%;
}
</style>
