<template>
  <div id="payment">
    <sui-step-group
      :steps="steps"
      size="small"
      fluid
      step-number="three"
      stackable
    />

    <div class="info">
      <sui-segment class="grid-segment">
        <sui-form id="form-checkout">
          <div v-show="current == 0">
            <sui-form-field>
              <label>Email</label>
              <input
                required
                type="email"
                name="cardholderEmail"
                id="form-checkout__cardholderEmail"
                v-model="form.formOne.email"
              />
            </sui-form-field>
            <sui-form-field>
              <label>Tipo de documento</label>
              <select
                name="identificationType"
                id="form-checkout__identificationType"
              ></select>
            </sui-form-field>
            <sui-form-field>
              <label>Nº do documento</label>
              <input
                required
                type="text"
                name="identificationNumber"
                id="form-checkout__identificationNumber"
                v-model="form.formOne.docNumber"
              />
            </sui-form-field>
          </div>
          <div v-show="current == 1">
            <sui-form-field>
              <label>Nome (no cartão de crédito)</label>
              <input
                required
                type="text"
                name="cardholderName"
                id="form-checkout__cardholderName"
                v-model="form.formTwo.name"
              />
            </sui-form-field>
            <sui-form-field>
              <label>Número do cartão de crédito</label>
              <input
                required
                type="text"
                name="cardNumber"
                id="form-checkout__cardNumber"
                v-model="form.formTwo.cardNumber"
                v-mask="'#### #### #### ####'"
              />
            </sui-form-field>
            <sui-form-fields fields="three">
              <sui-form-field>
                <label>Mês de vencimento</label>
                <input
                  required
                  type="text"
                  name="cardExpirationMonth"
                  id="form-checkout__cardExpirationMonth"
                  maxlength="2"
                  v-model="form.formTwo.cardExpMonth"
                />
              </sui-form-field>
              <sui-form-field>
                <label>Ano de vencimento</label>
                <input
                  required
                  type="text"
                  name="cardExpirationYear"
                  id="form-checkout__cardExpirationYear"
                  maxlength="2"
                  v-model="form.formTwo.cardExpYear"
                />
              </sui-form-field>
              <sui-form-field>
                <label>Código de segurança</label>
                <input
                  required
                  type="text"
                  name="securityCode"
                  id="form-checkout__securityCode"
                  v-model="form.formTwo.secCode"
                  maxlength="3"
                />
              </sui-form-field>
            </sui-form-fields>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>Banco Emissor</label>
                <select name="issuer" id="form-checkout__issuer"></select>
              </sui-form-field>
              <sui-form-field>
                <label>Parcelas</label>
                <select
                  name="installments"
                  id="form-checkout__installments"
                ></select>
              </sui-form-field>
            </sui-form-fields>
          </div>
          <div v-show="current == 2">
            <div v-if="!status" class="loader">
              <sui-loader active>Processando...</sui-loader>
            </div>
            <div v-else class="result">
              <sui-icon
                :name="status.status ? 'check' : 'times'"
                :color="status.status ? 'green' : 'red'"
                size="big"
              />
              {{ status.message }}
              <router-link to="/minhas-reservas" class="go-back">
                Voltar para reservas
              </router-link>
            </div>
          </div>
          <div class="buttons">
            <sui-button
              @click="goBackStep"
              content="Voltar"
              :disabled="current != 1"
              icon="arrow left"
              label-position="left"
            />
            <sui-button
              v-if="current == 1"
              type="submit"
              content="Confirmar"
              color="teal"
              icon="arrow right"
              label-position="right"
              id="form-checkout__submit"
              :loading="loading"
            />
            <sui-button
              v-else-if="current == 0"
              type="button"
              @click="skipStep"
              content="Prosseguir"
              color="teal"
              icon="arrow right"
              label-position="right"
            />
          </div>
        </sui-form>
      </sui-segment>
      <sui-segment class="grid-segment purchase-info">
        <sui-label ribbon="left" color="green" icon="lock">
          Compra Segura
        </sui-label>
        <img
          src="../../assets/images/mercadopago.png"
          class="mercadopago"
          alt="Icone Mercado Pago"
        />

        <h4 is="sui-header" dividing>
          <sui-icon name="cart" />
          Você está comprando:
        </h4>

        <p>
          <sui-icon name="volleyball ball" />
          Reserva de
          {{ payData.hours }} hora(s)
        </p>

        <sui-divider />

        <div class="total">
          <b> Total: </b>
          <h3>{{ payData.price }}</h3>
        </div>
      </sui-segment>
    </div>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

import { api } from "../../services/apiClient";

import { formatCurrency } from "../../helpers/format";

export default {
  data: () => ({
    steps: [
      {
        active: true,
        icon: "user",
        title: "Informações Pessoais",
        description: "Entre suas informações pessoais",
      },
      {
        disabled: true,
        icon: "payment",
        title: "Pagamento",
        description: "Entre as informações do cartão de crédito",
      },
      {
        disabled: true,
        icon: "check",
        title: "Finalizar",
        description: "Finalizando a sua reserva!",
      },
    ],
    current: 0,
    formCheck: {
      formOne: ["email", "docNumber"],
      formTwo: ["name", "cardNumber", "cardExpMonth", "cardExpYear", "secCode"],
    },
    form: {
      formOne: {},
      formTwo: {},
    },
    payData: {
      hours: null,
      price: null,
    },
    loading: false,
    status: null,
  }),
  methods: {
    skipStep() {
      const currentForm = this.current == 0 ? "One" : "Two";
      const formCheck = this.formCheck[`form${currentForm}`];
      const form = this.form[`form${currentForm}`];

      for (const field in formCheck)
        if (!(formCheck[field] in form)) {
          this.$notify({ type: "warn", text: "Preencha todos os campos!" });
          return;
        }

      if (!(this.current + 1 == this.steps.length)) {
        this.steps[this.current].active = false;
        this.steps[this.current + 1].active = true;
        this.steps[this.current + 1].disabled = false;
        this.current += 1;
      }
    },
    finalStep() {
      this.steps[0].active = this.steps[1].active = false;
      this.steps[1].disabled = false;
      this.steps[2].active = true;
      this.steps[2].disabled = false;
      this.current = 2;
    },
    goBackStep() {
      this.steps[this.current].active = false;
      this.steps[this.current - 1].active = true;
      this.current -= 1;
    },
  },
  async mounted() {
    const self = this;
    let data;
    let hoursInfo = null;
    let ispackage = true;

    this.form.formOne.email = this.$store.state.auth.user.email;

    let resId = this.$route.query.reservations;

    if (resId) {
      resId = resId.split(",");

      if (resId.length <= 1) {
        const resCall = await api.get(`reservation/${resId}`);
        data = resCall.data;
        hoursInfo = 1;
      } else {
        hoursInfo = resId.length;
        ispackage = false;
      }
    }

    if (data && data.confirmed) {
      this.status = {
        status: true,
        message: "O pagamento dessa reserva já foi feito!",
      };

      this.finalStep();

      return;
    }

    if (!hoursInfo) hoursInfo = this.$store.getters.getHours();

    const res = await api.get("payment", {
      params: { hours: hoursInfo, ispackage },
    });

    this.payData.hours = res.data.hours;
    this.payData.price = formatCurrency(res.data.price);

    /* eslint-disable no-undef*/
    const mp = new MercadoPago(process.env.VUE_APP_MERCADOPAGO_PUBLIC_KEY);

    if (this.$store.state.reservations.cardForm)
      this.$store.state.reservations.cardForm.unmount();

    this.$store.state.reservations.cardForm = mp.cardForm({
      amount: String(res.data.price),
      autoMount: true,
      form: {
        id: "form-checkout",
        cardholderName: {
          id: "form-checkout__cardholderName",
          placeholder: "Titular do cartão",
        },
        cardholderEmail: {
          id: "form-checkout__cardholderEmail",
          placeholder: "E-mail",
        },
        cardNumber: {
          id: "form-checkout__cardNumber",
          placeholder: "Número do cartão",
        },
        cardExpirationMonth: {
          id: "form-checkout__cardExpirationMonth",
          placeholder: "00",
        },
        cardExpirationYear: {
          id: "form-checkout__cardExpirationYear",
          placeholder: "00",
        },
        securityCode: {
          id: "form-checkout__securityCode",
          placeholder: "Código de segurança",
        },
        installments: {
          id: "form-checkout__installments",
          placeholder: "Parcelas",
        },
        identificationType: {
          id: "form-checkout__identificationType",
          placeholder: "Tipo de documento",
        },
        identificationNumber: {
          id: "form-checkout__identificationNumber",
          placeholder: "Número do documento",
        },
        issuer: {
          id: "form-checkout__issuer",
          placeholder: "Banco emissor",
        },
      },
      callbacks: {
        onFormMounted: (error) => {
          if (error)
            this.$notify({
              type: "error",
              text: "Ocorreu um erro ao montar o formulário de pagamento, tente novamente.",
            });
        },
        onSubmit: async function (e) {
          try {
            e.preventDefault();
            self.loading = true;

            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = self.$store.state.reservations.cardForm.getCardFormData();

            let reservationId = self.$route.query.reservations;
            if (reservationId) reservationId = reservationId.split(",");

            const data = {
              paymentData: {
                transactionAmount: amount,
                token,
                description: `Reserva de ${self.payData.hours} hora(s)`,
                installments,
                payment_method_id,
                issuer_id,
                payerEmail: email,
                payerIdentificationType: identificationType,
                payerIdentificationNumber: identificationNumber,
              },
              reservationId,
              packageInfo: {
                user: self.$store.getters.getUserId,
                hours: hoursInfo,
              },
            };

            self.finalStep();
            const res = await api.post("payment", data);
            if (res) {
              const data = res.data;
              self.$notify({
                type: data.status ? "success" : "error",
                text: data.message,
              });
              self.status = data;
            }
          } catch (err) {
            self.status = {
              status: false,
              message:
                "Ocorreu um erro com o pagamento, tente novamente mais tarde.",
            };
            Sentry.captureException(err);
          }
        },
      },
    });
  },
};
</script>

<style lang="scss" scoped>
#payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  margin: 0 12rem;

  @media (max-width: 1280px) {
    margin: 0 2rem;
  }

  font: 400 14px "Roboto" !important;

  form {
    margin: 1rem;
  }

  .info {
    width: 100%;
    display: grid;
    grid-template-columns: 2.5fr 1fr;

    @media (max-width: 1280px) {
      display: flex;
      flex-direction: column-reverse;

      .purchase-info {
        margin-left: 0 !important;
        margin-bottom: 2rem;
      }
    }

    .loader {
      padding: 2rem;
    }

    .result {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;

      .go-back {
        margin-top: 2rem;
      }
    }

    .grid-segment {
      height: 100%;

      &.purchase-info {
        margin-left: 2rem;
        margin-top: 0;

        .mercadopago {
          height: 1.5rem;
          float: right;
          margin-right: 0.5rem;
        }

        h4 {
          margin-top: 3rem;
        }

        .total {
          display: grid;
          grid-template-columns: 1fr 1fr;

          h3 {
            margin: 0;
            justify-self: right;
          }
        }
      }
    }
  }

  .buttons {
    margin-top: 1rem;
    float: right;

    button {
      padding: 0.5rem 1rem;
      font-size: 14px !important;
    }
  }
}
</style>
