import Cookies from "js-cookie";
import router from "../../../routes";

import { api } from "../../../services/apiClient";

const actions = {
  async signIn({ dispatch }, userData) {
    try {
      const response = await api.post("auth", userData);

      const { token, refreshToken, user } = response.data;

      dispatch("setAuth", { token, refreshToken, user });
    } catch {
      /* eslint-disable no-empty */
    }
  },

  async fetchUser({ commit, state }) {
    const token = Cookies.get("arena.token");

    if (token && !state.isAuthenticated) {
      try {
        const res = await api.get("user", {
          headers: { Authorization: `Bearer ${token}` },
        });
        commit("setUser", res.data);
        commit("setAuthentication");
      } catch {
        router.push("/");
      }
    }
  },

  signOut({ commit }, redirect = true) {
    Cookies.remove("arena.token", { path: "/" });
    Cookies.remove("arena.refresh_token", {
      path: "/",
    });

    commit("setUser", null);
    commit("setAuthentication");

    if (redirect) router.push("/");
  },

  setAuth({ commit }, { token, refreshToken, user }) {
    Cookies.set("arena.token", token);
    Cookies.set("arena.refresh_token", refreshToken);

    commit("setUser", { ...user });
    commit("setAuthentication");

    api.defaults.headers["Authorization"] = `Bearer ${token}`;
  },
};

export default actions;
