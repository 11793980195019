<template>
  <div id="footer">
    <p>Copyright 2021 © ARENA MM. All rights reserved.</p>
    <div>
      <router-link
        to="/politica-de-privacidade-e-termos-de-uso"
        class="nav-link"
      >
        Termos de uso e Política de privacidade
      </router-link>
    </div>
    <sui-button icon="angle up" circular basic @click="goToTop" class="top" />
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    goToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
#footer {
  width: 100vw;
  background: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  margin-top: 2rem;
  text-align: center;

  .top {
    position: absolute;
    right: 0;
    margin-right: 2rem;
    margin-top: 1rem;
  }

  @media (max-width: 1440px) {
    p,
    a {
      font-size: 16px;
      max-width: 50%;
    }
  }
}
</style>
