<template>
  <div id="app">
    <Navbar
      :secondary="secondaryNav.includes($route.name)"
      v-show="!noNav.includes($route.name)"
    />
    <router-view />
    <notifications position="bottom left" />
    <VueQueryDevTools />
  </div>
</template>

<script>
import { VueQueryDevTools } from "vue-query/devtools";

import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: {
    VueQueryDevTools,
    Navbar,
  },
  data: () => ({
    secondaryNav: ["Reservations"],
    noNav: ["Auth"],
  }),
};
</script>

<style lang="scss">
#app {
}
</style>
