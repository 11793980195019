<template>
  <sui-card id="price-card">
    <sui-card-content class="title">
      {{ title }}
    </sui-card-content>

    <sui-card-content class="card-content">
      <div class="price">
        <span>R$</span>
        <p>{{ price }} <span>,00</span></p>
        <p v-show="costPerHour" class="costPerHour">{{ costPerHour }}/hora</p>
      </div>

      <button
        @click="$router.push('/reservas')"
        v-if="isMainButton"
        class="button"
      >
        Fazer reserva
      </button>
      <router-link v-else-if="!packPageButton" to="/pacotes">
        Saiba mais
      </router-link>
      <button v-else @click="setPackage">Comprar agora</button>
    </sui-card-content>
  </sui-card>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "PriceCard",
  props: {
    title: String,
    price: String,
    costPerHour: String,
    isMainButton: { type: Boolean, default: false },
    packPageButton: { type: Boolean, default: false },
    hours: Number,
  },
  methods: {
    setPackage() {
      this.setHours(this.hours);

      if (!this.$store.state.auth.isAuthenticated) this.$router.push("/auth");
      else this.$router.push("/pagamento");
    },
    ...mapMutations(["setHours"]),
  },
};
</script>

<style lang="scss" scoped>
#price-card {
  margin: 0 2rem;
  /* width: 18rem; */

  a,
  button {
    @include button;
    background: $main-green;
    color: $white;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 2rem;
    padding: 1rem;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .price {
      color: $black;
      font-weight: bold;

      span {
        font-size: 2rem;
        margin-left: -3rem;
      }

      .costPerHour {
        font-size: 1.5rem;
        font-weight: normal;
        margin-bottom: 1rem;
        text-align: center;
      }

      p {
        font-size: 4rem;
        font-weight: bold;
        margin-top: -1rem;

        span {
          margin-left: -1rem;
        }
      }
    }
  }

  @media (max-width: 1440px) {
    width: 250px;

    .title {
      font-size: 1.5rem;
    }

    .card-content {
      .price {
        span {
          font-size: 1.5rem;
        }

        .costPerHour {
          font-size: 1rem;
        }

        p {
          font-size: 3rem;

          span {
            margin-left: -0.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 960px) {
    margin: 0;
  }
}
</style>
