<template>
  <div id="packages">
    <h1>Nossos pacotes</h1>

    <div class="packs">
      <PriceCard
        title="Pacote 3 horas"
        price="150"
        costPerHour="50,00"
        packPageButton
        class="price-card"
        :hours="3"
      />
      <PriceCard
        title="Pacote 8 horas"
        price="320"
        costPerHour="40,00"
        packPageButton
        class="price-card"
        :hours="8"
      />
      <PriceCard
        title="Pacote 16 horas"
        price="600"
        costPerHour="37,50"
        packPageButton
        class="price-card"
        :hours="16"
      />
    </div>

    <sui-accordion exclusive styled class="faq">
      <sui-accordion-title active>
        <sui-icon name="dropdown" />
        O que são pacotes?
      </sui-accordion-title>
      <sui-accordion-content active>
        <p>
          São horas que podem ser obtidas com desconto e que você pode usar
          livremente para fazer reservas quando quiser.
        </p>
      </sui-accordion-content>
      <sui-accordion-title>
        <sui-icon name="dropdown" />
        Por quanto tempo posso utilizar?
      </sui-accordion-title>
      <sui-accordion-content>
        <p>
          A partir da confirmação de compra, você terá um prazo de 1 ano para
          utilizar de suas horas.
        </p>
      </sui-accordion-content>
      <sui-accordion-title>
        <sui-icon name="dropdown" />
        Como posso adquirir um pacote?
      </sui-accordion-title>
      <sui-accordion-content>
        <p>
          Escolha o pacote que gostaria de adquirir e faça o pagamento via
          cartão de crédito. Quando o pagamento for confirmado, você poderá
          utilizar suas horas como quiser!
        </p>
      </sui-accordion-content>
    </sui-accordion>
  </div>
</template>

<script>
import PriceCard from "../components/Home/PriceCard.vue";

export default {
  components: { PriceCard },
  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss" scoped>
#packages {
  padding-top: 8rem;
  // margin: 0 33.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;

  .packs {
    display: flex;
    margin: 4rem 0;
  }

  .faq {
    width: 50%;
  }

  @media (max-width: 970px) {
    .packs {
      flex-direction: column;
      margin: 1rem 0;

      .price-card {
        margin: 1rem 0;
      }
    }

    .faq {
      width: 70%;
    }
  }
}
</style>
