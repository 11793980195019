<template>
  <div id="auth">
    <div class="forms">
      <a @click="$router.push('/')" class="go-back">
        <sui-icon name="angle left" />Voltar ao início
      </a>

      <sui-menu pointing secondary>
        <a
          is="sui-menu-item"
          v-for="item in items"
          :active="isActive(item)"
          :key="item"
          :content="item"
          @click="select(item)"
        />
      </sui-menu>
      <AuthForm
        v-if="isActive('Login')"
        buttonText="Login"
        @submit-form="login"
        :loading="loading"
      />
      <AuthForm
        v-else
        buttonText="Registrar"
        isRegister
        @submit-form="register"
        :loading="loading"
      />
    </div>
    <img src="../assets/images/auth.svg" alt="Pessoas jogando Volei de Praia" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { api } from "../services/apiClient";

import AuthForm from "../components/AuthForm.vue";

export default {
  components: {
    AuthForm,
  },
  data: () => ({
    active: "Login",
    items: ["Login", "Registrar"],
    loading: false,
  }),
  methods: {
    isActive(name) {
      return this.active === name;
    },
    select(name) {
      this.active = name;
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
    async login(loginInfo) {
      this.toggleLoading();

      try {
        await this.signIn(loginInfo);

        if (this.$store.state.auth.user.admin) this.$router.push("/admin");
        else if (this.$store.getters.checkReservation()) {
          const data = {
            reservation_date: this.$store.state.reservations.reservation.date,
            enter_time: this.$store.state.reservations.reservation.time,
            sport: this.$store.state.reservations.reservation.sport,
          };
          await this.createReservation(data);
        } else if (this.$store.getters.getHours()) {
          this.$router.push("/pagamento");
        } else this.$router.push("/minhas-reservas");
      } catch {
        /* eslint-disable no-empty */
      }

      this.toggleLoading();
    },
    async register(userInfo) {
      this.toggleLoading();

      try {
        await api.post("user", {
          ...userInfo,
          admin: false,
        });
        await this.signIn(userInfo);
        this.$router.push("/");
      } catch {}

      this.toggleLoading();
    },
    ...mapActions(["createReservation", "signIn"]),
  },
};
</script>

<style lang="scss" scoped>
#auth {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: $white;
  justify-content: space-between;

  .go-back {
    text-decoration: none;
    color: $black;
    cursor: pointer;
  }

  img {
    height: 100%;

    @media (max-width: 720px) {
      display: none;
    }
  }

  .forms {
    margin: 1rem 2rem;
    width: 100%;
    min-width: 18rem;
  }

  @media (max-width: 1280px) {
    height: 100%;
  }
}
</style>
