const mutations = {
  setReservation: (state, payload) => {
    state.reservation.date = payload.reservation_date;
    state.reservation.time = payload.enter_time;
    state.reservation.sport = payload.sport;

    state.hours = 1;
  },
  removeReservation: (state) => {
    state.reservation.date = null;
    state.reservation.time = null;
    state.reservation.sport = null;
  },
  setHours: (state, payload) => {
    state.hours = payload;
  },
};

export default mutations;
