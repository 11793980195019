<template>
  <div id="reservation-title">
    <span>{{ showData || text }}</span>
    <div class="icons">
      <sui-icon :name="icon" inverted size="large" />
      <img
        :class="extend ? '' : 'upside-down'"
        src="../../assets/images/arrow.svg"
        alt="Seta apontando"
        @click="$emit('toggle-component')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ReservationTitle",
  props: {
    showData: String,
    icon: String,
    text: String,
    extend: Boolean,
  },
};
</script>

<style lang="scss" scoped>
#reservation-title {
  background: $main-green;
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: bold;
    text-transform: uppercase;
    color: $white;
  }

  img {
    width: 2rem;
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    :nth-child(2) {
      margin-left: 1.5rem;
      cursor: pointer;
      transition: all 0.3s;
    }

    .upside-down {
      transition: all 0.3s;
      transform: rotate(180deg);
    }
  }
}
</style>
