import Vue from "vue";
import SuiVue from "semantic-ui-vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import * as VueGoogleMaps from "vue2-google-maps";
import VCalendar from "v-calendar";
import VueMask from "v-mask";
import Notifications from "vue-notification";
import { VueQueryPlugin } from "vue-query";
import GAuth from "vue-google-oauth2";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App.vue";

import router from "./routes";
import store from "./store";

import "./assets/scss/global.scss";
import "semantic-ui-css/semantic.min.css";
import "swiper/css/swiper.css";

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
  scope: "profile email",
  prompt: "select_account",
};

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: "https://e8744d1d4ce44aefa41d298fb7afa622@o1088235.ingest.sentry.io/6361181",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "mmarena.com.br", "arenamm.com.br", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

Vue.use(SuiVue);

Vue.use(VueAwesomeSwiper);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  },
});

Vue.use(VCalendar, {
  componentPrefix: "vc",
  masks: { weekdays: "WWW" },
});

Vue.use(VueMask);

Vue.use(Notifications);

Vue.use(VueQueryPlugin);

Vue.use(GAuth, gauthOption);

store.dispatch("fetchUser").then(() => {
  new Vue({
    router,
    render: (h) => h(App),
    store,
  }).$mount("#app");
});
