import axios from "axios";
import Cookies from "js-cookie";
import Vue from "vue";

import store from "../store";
import router from "../routes";

let isRefreshing = false;
let failedRequestQueue = [];

const signOut = () => {
  Cookies.remove("arena.token", { path: "/" });
  Cookies.remove("arena.refresh_token", {
    path: "/",
  });

  store.state.auth.user = null;
  store.state.auth.isAuthenticated = false;

  if (router.app.$route.path != "/") router.push("/");
};

export function setupAPIClient() {
  const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${Cookies.get("arena.token")}`,
    },
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        if (error.response.data?.error === "token.expired") {
          const refreshToken = Cookies.get("arena.refresh_token");
          const originalConfig = error.config;

          if (!isRefreshing) {
            isRefreshing = true;

            api
              .post("auth/refresh", {
                refresh_token: refreshToken,
              })
              .then((response) => {
                const { token, refreshToken: newRefreshToken } = response.data;

                Cookies.set("arena.token", token);
                Cookies.set("arena.refresh_token", newRefreshToken);

                api.defaults.headers["Authorization"] = `Bearer ${token}`;

                failedRequestQueue.forEach((request) => {
                  request.onSuccess(token);
                });
                failedRequestQueue = [];
              })
              .catch((err) => {
                failedRequestQueue.forEach((request) => request.onFailure(err));
                failedRequestQueue = [];

                signOut();
              })
              .finally(() => {
                isRefreshing = false;
              });
          }

          return new Promise((resolve, reject) => {
            failedRequestQueue.push({
              onSuccess: (token) => {
                originalConfig.headers["Authorization"] = `Bearer ${token}`;

                resolve(api(originalConfig));
              },
              onFailure: (err) => {
                reject(err);
              },
            });
          });
        } else {
          signOut();
        }
      } else if (error.response.status === 400)
        Vue.notify({ text: error.response.data.error, type: "error" });

      return Promise.reject(error);
    }
  );

  return api;
}
