const getters = {
  checkReservation: (state) => () => {
    return state.reservation.date ? true : false;
  },
  getHours: (state) => () => {
    return state.hours;
  },
};

export default getters;
