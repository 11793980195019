<template>
  <div id="contact-icon">
    <sui-icon :name="icon" size="huge" class="social-icon" />
    <h1>{{ title }}</h1>
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "ContactIcon",
  props: {
    icon: String,
    title: String,
    text: String,
  },
};
</script>

<style lang="scss" scoped>
#contact-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;

  h1 {
    margin-top: 1rem;
  }

  @media (max-width: 1440px) {
    h1 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }

    .social-icon {
      font-size: 3em;
    }
  }
}
</style>
