<template>
  <div id="time-picker">
    <ReservationTitle
      :showData="showData"
      icon="clock outline"
      :text="
        userHours
          ? `Escolha até ${userHours} horários`
          : 'Escolha seus horários'
      "
      :extend="extend"
      @toggle-component="toggle"
    />

    <div v-show="!extend" class="picker-section">
      <p v-if="!date">Escolha uma data !</p>
      <div v-else class="times">
        <sui-loader v-if="loading" active centered inline class="loader" />

        <ReservationTime
          v-else
          v-for="reservation in reservations"
          :key="reservation.enter_time"
          :reservation="reservation"
          @select-time="selectTime"
          @unselect-time="unselectTime"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "../../services/apiClient";
import { formatTime } from "../../helpers/format";

import ReservationTitle from "./ReservationTitle.vue";
import ReservationTime from "./ReservationTime.vue";

export default {
  name: "TimePicker",
  components: {
    ReservationTitle,
    ReservationTime,
  },
  data: () => ({
    reservations: [],
    extend: true,
    selected: [],
    loading: false,
    showData: "",
  }),
  props: { date: String, userHours: Number },
  methods: {
    toggle() {
      this.extend = !this.extend;
    },
    count(ary, classifier) {
      classifier = classifier || String;
      return ary.reduce(function (counter, item) {
        let p = classifier(item);
        /* eslint-disable no-prototype-builtins */
        counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
        return counter;
      }, {});
    },
    selectTime(reservation) {
      if (this.userHours)
        if (this.selected.length == this.userHours) {
          this.$notify({
            text: `Você só possui ${this.userHours} horas disponíveis`,
          });
          return;
        }

      this.selected.push(reservation.enter_time);

      const setReservationSelected = this.reservations.find(
        (reserv) => reserv.enter_time == reservation.enter_time
      );
      setReservationSelected.status = "Selecionado";

      this.$emit("get-time", this.selected);
    },
    unselectTime(reservation) {
      const removeReservationSelected = this.reservations.find(
        (reserv) => reserv.enter_time == reservation.enter_time
      );
      removeReservationSelected.status = "Disponível";

      const removeFromSelectedIndex = this.selected.findIndex(
        (reserv) => reserv == reservation.enter_time
      );
      this.selected.splice(removeFromSelectedIndex, 1);

      this.$emit("get-time", this.selected);
    },
  },
  watch: {
    async date(date) {
      this.extend = false;
      this.loading = true;

      const courtsReq = await api.get("court/how-many");
      const courtsQtde = courtsReq.data.quantity;

      const reservationsReq = await api.get("reservation", {
        params: { date },
      });

      const reservations = reservationsReq.data;

      let reservationsCount = [];

      if (reservations.length > 0)
        reservationsCount = this.count(reservations, (item) => {
          return item.enter_time;
        });

      let now = new Date();

      if (now.toISOString().split("T")[0] == this.date) now = now.getHours();
      else now = 5;

      this.reservations.splice(0);

      for (let i = now + 2; i <= 21; i++) {
        let status = "Disponível";

        const reservation = reservationsCount[i];
        if (reservation == courtsQtde) status = "Indisponível";

        this.reservations.push({
          enter_time: i,
          leave_time: i + 1,
          status,
        });
      }

      this.loading = false;
    },

    selected(times) {
      let dateString = "";

      times.forEach((time) => {
        if (dateString == "") dateString += formatTime(time);
        else dateString += ` | ${formatTime(time)}`;
      });

      this.showData = dateString;
    },
  },
};
</script>

<style lang="scss" scoped>
#time-picker {
  width: 100%;
}

.picker-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .times {
    width: 100%;
  }

  .loader {
    margin: 2rem 0;
  }
}
</style>
