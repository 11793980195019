<template>
  <div id="time-picker">
    <ReservationTitle
      :showData="selected"
      icon="volleyball ball"
      text="Escolha um esporte"
      :extend="extend"
      @toggle-component="toggle"
    />

    <div
      v-show="!extend"
      v-for="sport in sports"
      :key="sport.key"
      @click="selectSport(sport.value)"
      :class="['reservation-sport', sport.selected && 'selected']"
    >
      {{ sport.text }}
    </div>
  </div>
</template>

<script>
import ReservationTitle from "./ReservationTitle.vue";

export default {
  name: "SportsPicker",
  components: {
    ReservationTitle,
  },
  data: () => ({
    extend: true,
    selected: "",
    sports: [
      { key: 1, text: "Beach Tênis", value: "beach_tennis", selected: false },
      { key: 2, text: "Vôlei de Praia", value: "volley", selected: false },
      { key: 3, text: "Futevôlei", value: "footvolley", selected: false },
    ],
  }),
  props: { shouldOpen: Boolean },
  methods: {
    toggle() {
      this.extend = !this.extend;
    },
    selectSport(sport) {
      const unselect = this.sports.find((x) => x.selected == true);
      if (unselect) unselect.selected = false;

      const selected = this.sports.find((x) => x.value == sport);
      selected.selected = true;
      this.selected = selected.text;
      this.$emit("get-sport", sport);
    },
  },
  watch: {
    shouldOpen(value) {
      if (value) this.toggle();
    },
  },
};
</script>

<style lang="scss" scoped>
#time-picker {
  width: 100%;

  .reservation-sport {
    background: $light-green;
    cursor: pointer;
    transition: 0.2s;
    text-align: center;
    padding: 1rem 0;
    margin-top: 0.25rem;

    &:hover {
      background: $light-green-hover;
    }

    &.selected {
      background: $dark-green;
      color: $white;
      font-weight: 500;
    }
  }
}
</style>
