<template>
  <div id="my-reservations">
    <h1>Minhas reservas</h1>

    <h2 v-show="packageTime">
      Horas disponíveis: <span>{{ packageTime }}</span>
    </h2>

    <sui-loader v-if="loading" active centered inline class="not-reservation" />

    <div
      v-else-if="!loading && reservations.length == 0"
      class="not-reservation"
    >
      Você não possui nenhuma reserva
    </div>

    <div class="reservations-cards" v-else>
      <ReservationCard
        v-for="reservation in reservations"
        :key="reservation.id"
        :court="reservation.court"
        :enterTime="reservation.enterTime"
        :leaveTime="reservation.leaveTime"
        :date="reservation.date"
        :createdAt="reservation.createdAt"
        :confirmed="reservation.confirmed"
        :sport="reservation.sport"
        :userName="reservation.userName"
        :id="reservation.id"
      />
    </div>
  </div>
</template>

<script>
import { api } from "../../services/apiClient";


import { formatTime, formatDate } from "../../helpers/format";

import ReservationCard from "../../components/User/ReservationCard.vue";

export default {
  components: {
    ReservationCard,
  },
  data: () => ({
    reservations: [],
    loading: false,
    packageTime: null,
  }),
  methods: {
    async fetchReservations() {
      this.loading = true;

      const { data } = await api.get("reservations/user");

      const sports = {
        beach_tennis: "Beach Tênis",
        volley: "Vôlei de Praia",
        footvolley: "Futevôlei",
      };

      const user = this.$store.state.auth.user.name;

      this.reservations = data.map((reservation) => {
        const resDate = formatDate(reservation.reservation_date);
        const resCreatedAt = formatDate(reservation.createdAt);
        const resTime = formatTime(reservation.enter_time);
        const resTimeLeave = formatTime(reservation.leave_time);

        return {
          id: reservation._id,
          court: reservation.court.name,
          enterTime: resTime,
          leaveTime: resTimeLeave,
          date: resDate,
          createdAt: resCreatedAt,
          confirmed: reservation.confirmed,
          sport: sports[reservation.sport],
          userName: user,
        };
      });

      this.loading = false;
    },
    async fetchPackages() {
      const res = await api.get("package");
      this.packageTime = res.data.hoursLeft;
    },
  },
  async mounted() {
    await this.fetchReservations();
    await this.fetchPackages();
  },
};
</script>

<style lang="scss" scoped>
#my-reservations {
  padding-top: 8rem;
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    align-self: flex-start;
    font: 400 24px "Roboto";

    span {
      font-weight: bold;
    }
  }

  .not-reservation {
    margin-top: 2rem;
  }

  .reservations-cards {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4rem;

    @media (max-width: 1366px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1280px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
